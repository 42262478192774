import type { Ref, ComputedRef } from 'vue'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import type { Category, Game } from '../../types'

interface UseGameCardReturn {
  labels: ComputedRef<
    {
      code: string
      name: string
      color: string
    }[]
  >
  tooltipLabels: ComputedRef<
    {
      code: string
      name: string
      link: string
      mobileLink: string
    }[]
  >
  imagePath: ComputedRef<string>
  showPlayers: ComputedRef<boolean>
  notAvailableInRegion: ComputedRef<boolean>
}

export default function useGameCard(
  game: Ref<Game | null>,
  showPlayerCount?: Ref<boolean>,
): UseGameCardReturn {
  const { getCategoryByCode } = useCasinoStore()
  const { settings } = storeToRefs(useSettingsStore())

  const gameCategories = computed(() => {
    if (!game.value) return []

    return game.value.categories
      .map(({ code }) => getCategoryByCode(code) as Category)
      .filter(Boolean)
  })

  const labels = computed(() =>
    gameCategories.value
      .filter((category) => category.visibleForGameCard)
      .slice(0, 3)
      .map((category) => ({
        code: category.code,
        name: category.labelName || category.name || category.code,
        color: category.labelColor
          ? `#${category.labelColor}`
          : 'var(--palette-brand-600)',
      })),
  )

  const tooltipLabels = computed(() =>
    gameCategories.value.map((category) => ({
      code: category.code,
      name: category.name || category.code,
      link: `/casino/categories/${category.code}`,
      mobileLink: `/mobile/casino/categories/${category.code}`,
    })),
  )

  const imagePath = computed(() => `url('${game.value?.image}'`)

  const showPlayers = computed(() => {
    if (!showPlayerCount?.value) return false
    if (
      game.value?.playersCount &&
      settings.value?.casinoGameMinPlayersNumberToShow
    ) {
      return (
        game.value?.playersCount >=
        settings.value.casinoGameMinPlayersNumberToShow
      )
    }
    return false
  })

  const notAvailableInRegion = computed(() =>
    game.value ? game.value.forbiddenByCountry : false,
  )

  return {
    labels,
    tooltipLabels,
    imagePath,
    showPlayers,
    notAvailableInRegion,
  }
}
